<script lang="ts">
import type { FormElementSize } from '@legacy-studio/library/dist/src/shared/base/FormElement/types';
import { getFormElementIconClasses } from '@legacy-studio/library';
</script>

<script setup lang="ts">
import { useIMask } from 'vue-imask';

import {
	BaseIcon,
	type BaseIconName,
	FormElementWrapper,
	generateId,
	getFormElementClasses,
} from '@legacy-studio/library';
import type { TextFieldType } from './types';
import { iMaskOptions } from './iMaskOptions';

export interface Props {
	id?: string;
	modelValue: string | number;
	size?: FormElementSize;
	label?: string;
	type?: TextFieldType;
	leftIconStyle?: BaseIconName;
	rightIconStyle?: BaseIconName;
	placeholder?: string;
	disabled?: boolean;
	message?: string;
	successMessage?: string;
	errorMessage?: string;
	autocomplete?: string;
	maskType?: 'currency' | 'positiveInteger' | 'positiveFloat' | 'positivePercentFloat';
	maskCustomOptions?: object;
}

interface Emits {
	(e: 'update:model-value', value: string): void;
	(e: 'change', value: string): void;
}

interface Slots {
	'message'?: any;
	'right'?: { classes: ReturnType<typeof getFormElementIconClasses> };
	'left-icon'?: { leftIconClasses: ReturnType<typeof getFormElementIconClasses> };
}

const props = withDefaults(defineProps<Props>(), {
	id: '',
	label: '',
	leftIconStyle: undefined,
	rightIconStyle: undefined,
	placeholder: '',
	disabled: false,
	size: 'regular',
	message: '',
	successMessage: '',
	errorMessage: '',
	type: 'text',
	autocomplete: '',
	maskCustomOptions: undefined,
	maskType: undefined,
});

const rightId = computed(() => props.id || generateId());

const emit = defineEmits<Emits>();

const slots = defineSlots<Slots>();

const inputClasses = computed(() => getFormElementClasses({ ...props, native: true, leftIconStyle: !!(props.leftIconStyle || slots['left-icon']) }));

const leftIconClasses = computed(() => getFormElementIconClasses({ ...props, type: 'left' }));
const rightIconClasses = computed(() => getFormElementIconClasses({ ...props, type: 'right' }));

const localModel = computed({
	get() {
		return String(props.modelValue);
	},
	set(value) {
		emit('update:model-value', String(value));
	},
});

const hidePassword = ref(true);
const rightIconComputedStyle = computed(() => {
	if (props.type === 'password') {
		if (hidePassword.value) {
			return 'fill--eye-off';
		}
		else {
			return 'fill--eye';
		}
	}
	else {
		return props.rightIconStyle;
	}
});

const handleRightIconClick = () => {
	if (props.type === 'password') {
		hidePassword.value = !hidePassword.value;
	}
};

const computedType = computed<TextFieldType>(() => {
	if (props.type === 'password') {
		if (hidePassword.value) {
			return 'password';
		}
		else {
			return 'text';
		}
	}
	else {
		return props.type;
	}
});

const onChange = () => {
	emit('change', localModel.value);
};

const maskOptions = computed(() => {
	if (props.maskType === 'currency') {
		return iMaskOptions.currency;
	}
	else if (props.maskType === 'positiveInteger') {
		return iMaskOptions.positiveInteger;
	}
	else if (props.maskType === 'positiveFloat') {
		return iMaskOptions.positiveFloat;
	}
	else if (props.maskType === 'positivePercentFloat') {
		return iMaskOptions.positivePercentFloat;
	}
	else {
		return props.maskCustomOptions;
	}
});

const { el: target, unmasked, masked } = useIMask(maskOptions.value, {
	onAccept: () => {
		localModel.value = unmasked.value;
	},
	defaultUnmaskedValue: localModel.value,
});

watch(localModel, (newValue) => {
	if (!newValue) {
		masked.value = '';
	}
	else if ((props.maskType || props.maskCustomOptions) && unmasked.value !== newValue) {
		masked.value = newValue;
	}
});
</script>

<template>
	<FormElementWrapper
		:id="rightId"
		:label="label"
		:message="message"
		:error-message="errorMessage"
		:success-message="successMessage"
	>
		<template #default>
			<div class="relative">
				<slot
					name="left-icon"
					v-bind="{ classes: leftIconClasses }"
				>
					<BaseIcon
						v-if="leftIconStyle"
						:name="leftIconStyle"
						:class="leftIconClasses"
					/>
				</slot>
				<input
					:id="rightId"
					ref="target"
					:class="inputClasses"
					:placeholder="placeholder"
					:disabled="disabled"
					:type="computedType"
					:autocomplete="autocomplete"
					@change="onChange"
				>
				<slot
					name="right"
					v-bind="{ classes: rightIconClasses }"
				>
					<BaseIcon
						v-if="rightIconComputedStyle"
						:name="rightIconComputedStyle"
						:class="rightIconClasses"
						@click="handleRightIconClick"
					/>
				</slot>
			</div>
		</template>

		<template
			v-if="$slots.message"
			#message
		>
			<slot name="message" />
		</template>
	</FormElementWrapper>
</template>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
