const currency = {
	mask: '$ num',
	blocks: {
		num: {
			mask: Number,
			thousandsSeparator: ',',
			radix: '.',
			scale: 2,
			padFractionalZeros: true,
			normalizeZeros: true,
			mapToRadix: ['.'],
			min: 0,
		},
	},
};
const positiveInteger = {
	mask: Number,
	min: 0,
	thousandsSeparator: ' ',
	scale: 0,
};

const positiveFloat = {
	mask: Number,
	min: 0,
	thousandsSeparator: ' ',
	scale: 2,
};
const positivePercentFloat = {
	mask: Number,
	min: 0,
	max: 100,
	scale: 2,

};
export const iMaskOptions = {
	currency,
	positiveInteger,
	positiveFloat,
	positivePercentFloat,
};
